import GroupCardTitle from "./GroupCardTitle";
import React from "react";
import NumericCard from "./NumericCard";
import PercentCard from "./PercentCard";
import TimeCard from "./TimeCard";
import {Summary as SummaryModel} from "../../domain/Summary";
import {useTranslation} from "react-i18next";
import useDateFiltersHelper from "../../hooks/filters/use-date-filters-helper";
import InfoTooltip from "../Common/InfoTooltip";

interface Props {
    data?: SummaryModel|null
}

const Summary = ({data}: Props) => {
    const {t} = useTranslation()
    const {dateRange, isRealTime, isRealTimeThirtyMinutes, filterDateLabel} = useDateFiltersHelper();

    return (
        <div className="cc-summary">
            {isRealTime && <div className="oc-group cc-summary__real-time">
                <GroupCardTitle title={t("realtime")} subtitle={isRealTimeThirtyMinutes ? t("last 30 minutes") : t("last 5 minutes")} />
                <div className="oc-group oc-group--nowrap oc-group--summary">
                    <NumericCard
                        title={t("active users")}
                        data={data?.concurrent}
                        isMain={true}
                    />
                    <PercentCard
                        title={t("recirculation")}
                        data={data?.recirculation}
                        tooltip={<InfoTooltip title={t("exclude amp from pageviews")} />}
                    />
                </div>
            </div>}
            <div className="oc-group oc-group--min-content">
                {isRealTime ?
                    <GroupCardTitle title={t("today")} subtitle={t("accumulated of the day")} /> :
                    <GroupCardTitle
                        title={t(dateRange.label)}
                        subtitle={t("accumulated of") + " " + filterDateLabel}
                    />
                }

                <div className="oc-group oc-group--nowrap oc-group--summary">
                    <NumericCard title={t("unique users")} data={data?.uniqueUsers} />
                    <NumericCard title={t("visits")} data={data?.visits} />
                    <NumericCard title={t("pageviews")} data={data?.pageViews} />
                    <TimeCard title={t("reading time")} data={data?.readingTime} />
                </div>
            </div>
        </div>
    )
}

export default Summary