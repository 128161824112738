import {useCallback, useEffect, useMemo, useState} from "react";
import ApiClient from "../client/ApiClient";
import {ExternalAppsAccess} from "../../server/domain/Token";
import {TOKEN_KEY} from "../domain/Constants";

const useAuthenticationExternalApps = () => {
    const client = useMemo(() => new ApiClient(), [])
    const [externalAppsAccess, setExternalAppsAccess] = useState<ExternalAppsAccess>({ analytics: false, advertising: false, ai: false})

    const getExternalAppsData = useCallback(async () => {
        const token = localStorage.getItem(TOKEN_KEY)
        if (!token){
            return;
        }

        const access = await client.getExternalAppsAccess(token);

        setExternalAppsAccess({
            analytics: access?.analytics || false,
            advertising: access?.advertising || false,
            ai: access?.ai || false
        });

    }, [setExternalAppsAccess, client])

    useEffect(() => {
        getExternalAppsData().then()
    }, [getExternalAppsData])

    return {
        externalAppsAccess
    }
}

export default useAuthenticationExternalApps