import logoDesktop from '../../assets/logo-analytics-desktop.svg';
import logoMobile from '../../assets/logo-analytics-mobile.svg';
import IconGeneric from './IconGeneric';
import { useContext, useMemo, useState } from 'react';
import classnames from 'classnames';
import { COMITIUM_URL_KEY } from "../../domain/Constants";
import { AppContext } from "../../pages/Home";
import useAuthenticationExternalApps from "../../hooks/use-authentication-external-apps";
import {useTranslation} from "react-i18next";

const Logo = () => {
    const {i18n} = useTranslation()
    const [appsDropdown, setAppsDropdown] = useState<boolean>(false);
    const { accounts, selectedAccount } = useContext(AppContext);
    const comitiumUrl = useMemo(() => {
        return localStorage.getItem(COMITIUM_URL_KEY) ?? (selectedAccount ? selectedAccount.comitium_url : (accounts[0].comitium_url ?? null))
    }, [accounts, selectedAccount])
    const {externalAppsAccess} = useAuthenticationExternalApps()
    const showDropdown = useMemo(() => !!comitiumUrl || externalAppsAccess.advertising || externalAppsAccess.ai, [comitiumUrl, externalAppsAccess])

    return (
        <>
            <div className={classnames('cc-header__item-wrapper', { 'is-open': appsDropdown })}>
                <div className="cc-header__item mc-logo">
                    <img className="mc-logo__image--desktop" src={logoDesktop} alt={"Logo Comitium Analytics"} />
                    <img className="mc-logo__image--tablet" src={logoMobile} alt={"Logo Comitium Analytics"} />
                </div>
                <div className={classnames('cc-header__dropdown-arrow', { 'is-open': appsDropdown })}
                    onClick={() => { if (showDropdown) setAppsDropdown(!appsDropdown) }}
                >
                    {showDropdown && <IconGeneric iconName="arrow-down-ico" className="mc-icon__generic--size-24" />}
                </div>
                <>
                    <ul className="cc-header__dropdown">
                        {comitiumUrl &&
                            <li className='cc-header__dropdown-item'>
                                <IconGeneric
                                    iconName="logo-comitium"
                                    className="mc-logo mc-logo__app--comitium"
                                    onClick={() => {
                                        window.open(comitiumUrl, "_self");
                                    }}
                                />
                            </li>
                        }
                        {/*
                        {externalAppsAccess.advertising &&
                            <li className='cc-header__dropdown-item'>
                                <IconGeneric
                                    iconName="logo-advertising-desktop"
                                    className="mc-logo mc-logo__app--advertising"
                                    onClick={() => {
                                        window.open(comitiumUrl + i18n.language + '/advertising-redirect', "_self");
                                    }}
                                />
                            </li>
                        }
                        {externalAppsAccess.ai &&
                            <li className='cc-header__dropdown-item'>
                                <IconGeneric
                                    iconName="logo-ai-desktop"
                                    className="mc-logo mc-logo__app--ai"
                                    onClick={() => {
                                        window.open(comitiumUrl + i18n.language + '/cai-redirect', "_self");
                                    }}
                                />
                            </li>
                        }
                        */}
                    </ul>
                </>
            </div>
        </>
    );
}

export default Logo;