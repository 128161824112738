import '../App.scss';
import Header from "../components/Header/Header";
import {Outlet} from "react-router-dom";
import {Account} from "../domain/Account";
import {createContext, Dispatch, SetStateAction, useState} from "react";
import {useAccounts} from "../hooks/use-accounts";
import LoadingFull from '../components/Common/Loading/LoadingFull';
import useAuthenticationExternalApps from "../hooks/use-authentication-external-apps";
import {ExternalAppsAccess} from "../../server/domain/Token";

interface AppContextInterface {
    selectedAccount: Account|null
    setSelectedAccount: Dispatch<SetStateAction<Account|null>>
    accounts: Account[]
    externalAppsAccess: ExternalAppsAccess
}

export const AppContext = createContext<AppContextInterface>({} as AppContextInterface)

const Home = () => {
    const [selectedAccount, setSelectedAccount] = useState<Account|null>(null)
    const {accounts} = useAccounts();
    const {externalAppsAccess} = useAuthenticationExternalApps();

    const data = {
        selectedAccount,
        setSelectedAccount,
        accounts,
        externalAppsAccess
    }

    if (!data || accounts.length === 0) {
        return <LoadingFull/>;
    }

    return (
        <AppContext.Provider value={data}>
            <div className="comitium-analytics">
                <Header />
                <div className="oc-flex oc-flex--no-wrap cc-main__wrapper">
                    <Outlet/>
                </div>
            </div>
        </AppContext.Provider>
    );
}

export default Home;
